<template>
  <transition name="el-zoom-in-top" @after-leave="doDestroy">
    <div class="detail-container el-popper"
         v-show="showPopper"
         v-clickoutside="close"
         :style="{ width: `${dropdownWidth}px` }">

      <el-form class="fields-container" :model="addressObjectsValue" :rules="rules" ref="form">
        <template v-for="(field, index) in fields">
          <el-form-item :key="`${index}_`" :prop="field.name" class="search-field">
            <component
              :is="`${field.type}-field`"
              :label="$locale.address_service.address_levels[field.name]"
              :type="field.name"
              :key="index"
              v-model="addressObjectsValue[field.name]"
              @change="setValue(field.name, $event)"
              @open-popup="isPopupFieldOpened = true"
              @close-popup="isPopupFieldOpened = false"
            ></component>
          </el-form-item>
        </template>
      </el-form>
      <div style="float:right; margin-right: 10px">
        <el-button size="mini" @click="addressObjectsValue = {}">{{ $locale.main.button.clear }}</el-button>
        <el-button size="mini" type="primary" @click="save">{{ $locale.main.button.ok }}</el-button>
      </div>
    </div>
  </transition>
</template>

<script>
import Popper from 'element-ui/src/utils/vue-popper'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import SelectField from '@/core/infrastructure/components/AddressField/SelectField'
import HouseField from '@/core/infrastructure/components/AddressField/HouseField'

import LEVELS from './levels.json'

export default {
  name: 'address-objects',
  components: { HouseField, SelectField },
  directives: { Clickoutside },
  mixins: [Popper],
  provide () {
    return {
      getPreviousValue: this.getPreviousValue
    }
  },
  props: {
    requiredLevel: {
      type: String
    },
    placement: {
      type: String,
      default: 'bottom-end'
    },
    isActive: {
      type: Boolean,
      default: false
    },
    dropdownWidth: {
      type: Number,
      default: 100
    },
    level: {
      type: String
    },
    selectedAddress: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      addressObjectsValue: {
        subject: null,
        municipal_area: null,
        city: null,
        locality: null,
        planning_structure: null,
        street: null,
        house: null,
        flat: null
      },
      parent: this.$parent,
      isPopupFieldOpened: false
    }
  },
  updated () {
    this.$nextTick(_ => {
      this.popperJS && this.updatePopper()
    })
  },
  mounted () {
    this.$parent.popperElm = this.popperElm = this.$el
    this.referenceElm = this.$parent.$refs.field.$refs.reference.$refs.input
  },
  methods: {
    close () {
      if (!this.isPopupFieldOpened) {
        let isOpened = JSON.parse(JSON.stringify(this.isActive))
        setTimeout(() => {
          if (isOpened) {
            this.$emit('update:is-active', false)
          }
        }, 50)
      }
    },
    save () {
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          let lastElement = {}
          LEVELS.forEach((item) => {
            if (this.addressObjectsValue[item.name]) {
              lastElement = {
                type: item.name,
                data: this.addressObjectsValue[item.name]
              }
            }
          })
          this.$emit('set-address', lastElement)
          this.close()
        }
      })
    },
    async setAddressObjectsValue (address = {}) {
      if (!address) {
        return false
      }
      for (let index = 0; index < LEVELS.length; index++) {
        let item = LEVELS[index]
        if (item.name === 'house') {
          if (address.house_houseid) {
            await this.loadHouseByGuid(address.house_houseid)
          } else if (address.house) {
            this.setValue('house', {
              house: address.house
            })
          }
        } else if (address[`${item.name}_guid`]) {
          await this.loadByGuid(address[`${item.name}_guid`], item.name)
        }
      }
    },
    setValue (type, value) {
      this.$set(this.addressObjectsValue, type, value)
    },
    async loadByGuid (guid, type) {
      let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/fias/address_objects/${guid}`)
      if (!data) {
        return false
      }
      let option = {
        id: data.data.aoid,
        guid: data.data.aoguid,
        name: `${data.data.formalname} ${data.data.shortname}`
      }
      this.setValue(type, option)
    },
    async loadHouseByGuid (guid) {
      let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/fias/houses/${guid}`)
      if (!data) {
        return false
      }
      let option = {
        house_houseid: data.data.houseid,
        house: data.data.housenum
      }
      this.setValue('house', option)
    },
    getPreviousValue (type) {
      let level = LEVELS.find((item) => item.name === type)
      let previousValue = null
      level.previous.forEach((item) => {
        if (this.addressObjectsValue[item]) {
          previousValue = this.addressObjectsValue[item]
        }
      })

      return previousValue
    }
  },
  computed: {
    rules () {
      let result = {}
      if (!this.requiredLevel) {
        return result
      }
      result[this.requiredLevel] = [
        { required: true, message: this.$locale.main.message.required_field }
      ]
      return result
    },
    fields () {
      let result = []
      LEVELS.every((item) => {
        result.push(item)
        return item.name !== this.level
      })

      return result
    }
  },
  watch: {
    selectedAddress: {
      deep: true,
      handler (value) {
        this.setAddressObjectsValue(value)
      }
    },
    isActive (value) {
      this.showPopper = value
    }
  }
}
</script>

<style scoped>
.detail-container {
  width: 100%;
  background: #F4F5F6;
  border: 1px solid #E9EBED;
  box-sizing: border-box;
  border-radius: 8px;
  padding-bottom: 10px;
}
.detail-container:focus {
  outline: none;
}

.search-field {
  margin: 5px 10px 0px 10px;
}

.fields-container {
  display: grid;
  grid-template-columns: 50% 50%;
}
</style>
